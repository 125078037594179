<template>
  <g ref="slider"></g>
</template>

<script>
import patternify from '../d3-assets/patternify'

export default {
  props: [
    'sliderWidth',
    'staticDomain',
    'initialDomain',
    'maximumSliderRange',
    'sliderLabelFormat'
  ],
  data () {
    return {
      padding: 90,
      zoomArea: [],
      domain: [],
      sliderBackColor: '#e9bfbe',
      circleColor: '#e1abaa',
      darkColor: '#c5716f',
      sliderHandlerRadius: 12
    }
  },
  computed: {
    sliderScale () {
      return this.$d3
        .scaleBand()
        .domain(this.staticDomain)
        .range([0, this.sliderWidth - this.padding * 2])
    }
  },
  methods: {
    drawSlider () {
      var width = this.sliderWidth
      var padding = this.padding
      var self = this
      var selection = this.$d3.select(this.$refs.slider)
      var startX = 0
      var currentSelectedArea

      var sliderStartText = this.sliderLabelFormat ? this.sliderLabelFormat(this.staticDomain[0]) : this.staticDomain[0]
      var sliderEndText = this.sliderLabelFormat ? this.sliderLabelFormat(this.staticDomain[this.staticDomain.length - 1]) : this.staticDomain[this.staticDomain.length - 1]

      if (this.zoomArea.length) {
        currentSelectedArea = this.zoomArea
      } else {
        currentSelectedArea = [
          this.sliderScale(this.domain[0]),
          this.sliderScale(this.domain[this.domain.length - 1]) + this.sliderScale.bandwidth()
        ]
      }

      // append texts
      patternify({
        container: selection,
        tag: 'text',
        selector: 'sliderStartTxt'
      })
        .text(sliderStartText)
        .attr('y', -1.5)
        .attr('fill', '#fff')
        .attr('font-family', 'Roboto')

      patternify({
        container: selection,
        tag: 'text',
        selector: 'sliderEndTxt'
      })
        .text(sliderEndText)
        .attr('fill', '#fff')
        .attr('y', -2)
        .attr('x', width - padding + 20)
        .attr('font-family', 'Roboto')

      // slider group
      var slider = patternify({
        container: selection,
        tag: 'g',
        selector: 'slider'
      })
        .attr('overflow', 'visible')
        .attr('transform', `translate(${padding}, ${-6})`)

      // background line
      patternify({
        container: slider,
        tag: 'line',
        selector: 'backgroundLine'
      })
        .attr('stroke-width', 8)
        .attr('stroke', this.sliderBackColor)
        .attr('stroke-linecap', 'round')
        .attr('x2', width - padding * 2)

      // foreground line
      const foregroundLine = patternify({
        container: slider,
        tag: 'line',
        selector: 'foregroundLine'
      })
        .attr('stroke-width', 3)
        .attr('stroke', '#fff')
        .attr('cursor', 'pointer')
        .attr('stroke-linecap', 'round')
        .attr('x1', currentSelectedArea[0])
        .attr('x2', currentSelectedArea[1])
        .on('mouseover', lineMouseOver)
        .on('mouseout', lineMouseOut)
        .call(
          this.$d3
            .drag()
            .on('start', lineDragStart)
            .on('drag', lineDrag)
            .on('end', lineDragEnd)
        )

      // first circle group
      const firstCircleGroup = patternify({
        container: slider,
        tag: 'g',
        selector: 'firstCircleGroup'
      })
        .attr('transform', `translate(${currentSelectedArea[0]})`)
        .call(
          this.$d3.drag().on('drag', d => {
            return dragged(d, 'first')
          })
        )

      // second circle group
      var secondCircleGroup = patternify({
        container: slider,
        tag: 'g',
        selector: 'secondCircleGroup'
      })
        .attr('transform', `translate(${currentSelectedArea[1]})`)
        .call(
          this.$d3.drag().on('drag', d => {
            return dragged(d, 'second')
          })
        )

      patternify({
        container: firstCircleGroup,
        tag: 'circle',
        selector: 'firstCircle'
      })
        .attr('cursor', 'pointer')
        .attr('r', this.sliderHandlerRadius)
        .attr('fill', this.circleColor)
        .attr('stroke', this.darkColor)
        .attr('stroke-width', 1.5)

      patternify({
        container: secondCircleGroup,
        tag: 'circle',
        selector: 'secondCircle'
      })
        .attr('cursor', 'pointer')
        .attr('r', this.sliderHandlerRadius)
        .attr('fill', this.circleColor)
        .attr('stroke', this.darkColor)
        .attr('stroke-width', 1.5)

      patternify({
        container: firstCircleGroup,
        tag: 'line',
        selector: 'vBarFirst',
        data: [-1, 0, 1]
      })
        .attr('x1', d => d * 2.5)
        .attr('x2', d => d * 2.5)
        .attr('y1', -4)
        .attr('y2', 4)
        .attr('stroke', '#fff')
        .attr('stroke-width', 0.8)

      patternify({
        container: secondCircleGroup,
        tag: 'line',
        selector: 'vBarSecond',
        data: [-1, 0, 1]
      })
        .attr('x1', d => d * 2.5)
        .attr('x2', d => d * 2.5)
        .attr('y1', -4)
        .attr('y2', 4)
        .attr('stroke', '#fff')
        .attr('stroke-width', 0.8)

      // drag handlers
      function dragged (d, flag) {
        var cx = getDragCoord()
        if (flag === 'first') {
          if (
            cx >
            currentSelectedArea[1] -
              self.sliderScale.bandwidth() * self.maximumSliderRange
          ) {
            cx =
              currentSelectedArea[1] -
              self.sliderScale.bandwidth() * self.maximumSliderRange
          }
          currentSelectedArea[0] = cx
          firstCircleGroup.attr(
            'transform',
            `translate(${currentSelectedArea[0]})`
          )
          foregroundLine.attr('x1', cx)
        } else {
          if (
            cx <
            currentSelectedArea[0] +
              self.sliderScale.bandwidth() * self.maximumSliderRange
          ) {
            cx =
              currentSelectedArea[0] +
              self.sliderScale.bandwidth() * self.maximumSliderRange
          }
          currentSelectedArea[1] = cx
          secondCircleGroup.attr(
            'transform',
            `translate(${currentSelectedArea[1]})`
          )
          foregroundLine.attr('x2', cx)
        }

        dragEnd()
      }

      function dragEnd () {
        self.zoomArea = currentSelectedArea
        const newDomain = getNewDomain()
        self.domain = newDomain

        self.$emit('dragged', self.domain)
      }

      function getNewDomain () {
        return getLiveDomain(currentSelectedArea)
      }

      function getLiveDomain (coords) {
        const sliderBandWidth = self.sliderScale.bandwidth()
        const f = Math.round(coords[0] / sliderBandWidth)
        const s = Math.round(coords[1] / sliderBandWidth)

        return self.staticDomain.slice(f, s + 1)
      }

      function getDragCoord () {
        let cx = self.$d3.event.x
        if (cx < 0) {
          cx = 0
        } else if (cx > width - padding * 2) {
          cx = width - padding * 2
        }
        return cx
      }

      function getLineDragCoords () {
        let dx = self.$d3.event.x - startX
        if (currentSelectedArea[0] + dx <= 0) {
          dx = 0 - currentSelectedArea[0]
        }
        if (currentSelectedArea[1] + dx >= width - padding * 2) {
          dx = width - padding * 2 - currentSelectedArea[1]
        }
        return [currentSelectedArea[0] + dx, currentSelectedArea[1] + dx]
      }

      function lineDragStart () {
        startX = self.$d3.event.x
      }

      function lineDrag () {
        const coords = getLineDragCoords()
        firstCircleGroup.attr('transform', `translate(${coords[0]})`)
        secondCircleGroup.attr('transform', `translate(${coords[1]})`)
        foregroundLine.attr('x1', coords[0]).attr('x2', coords[1])

        const newDomain = getLiveDomain(coords)
        self.domain = newDomain

        self.$emit('dragged', self.domain)
      }

      function lineDragEnd () {
        const coords = getLineDragCoords()
        currentSelectedArea[0] = coords[0]
        currentSelectedArea[1] = coords[1]
        self.zoomArea = currentSelectedArea
        startX = 0
      }

      function lineMouseOver () {
        const that = self.$d3.select(this)
        that
          .transition()
          .duration(250)
          .attr('stroke-width', 7)
      }

      function lineMouseOut () {
        const that = self.$d3.select(this)
        that
          .transition()
          .duration(250)
          .attr('stroke-width', 3)
      }
    }
  },
  watch: {
    sliderWidth () {
      if (this.sliderWidth) {
        this.drawSlider()
      }
    }
  },
  mounted () {
    this.domain = this.initialDomain ? this.initialDomain : this.staticDomain.slice(0, this.maximumSliderRange + 1)
    this.drawSlider()
  }
}
</script>
