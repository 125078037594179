<template>
  <div class="chart-wrapper">
    <svg :width="width" :height="height">
      <g :transform="`translate(${padding.left}, ${padding.top})`" class="chart" />
      <line
        :x1="0"
        :x2="width"
        :y1="padding.top + chartHeight + hoverrectMehrYBottom"
        :y2="padding.top + chartHeight + hoverrectMehrYBottom"
        :stroke="divisionLineColor"
        stroke-width="0.5"
      />
      <g
        :transform="`translate(${sliderMargin}, ${padding.top + chartHeight + hoverrectMehrYBottom + 35})`"
        class="slider-wrapper"
      >
        <slider v-if="sliderWidth"
          :sliderWidth="sliderWidth"
          :staticDomain="staticDomain"
          :maximumSliderRange="maximumSliderRange"
          :sliderLabelFormat="sliderLabelFormat"
          @dragged="onSliderDrag"
        />
      </g>
      <tooltip
        :svgWidth="width"
        :svgHeight="height"
        :obj="tooltipObj"
        :handleOverflowX="true"
        :handleOverflowY="false"
      />
    </svg>
  </div>
</template>

<script>
import Tooltip from '../d3-assets/Tooltip'
import Slider from '../d3-assets/Slider'
import patternify from '../d3-assets/patternify'

export default {
  props: {
    graphData: {
      type: Array,
      required: true
    },
    graphOptions: {
      type: Object,
      required: true
    }
  },
  components: {
    tooltip: Tooltip,
    slider: Slider
  },
  data () {
    return {
      width: 0,
      height: 550,
      transitionTime: 750,
      padding: {
        left: 110,
        right: 15,
        top: 35,
        bottom: 15
      },
      sliderHeight: 180,
      hoverrectMehrYBottom: 60,
      mehrHeight: 60,
      axisCircleSize: 4,
      capacityPercentage: 0.2,
      barStroke: '#445B7C',
      divisionLineColor: '#404A65',
      circleColor: '#0065FF',
      darkColor: '#A5ADBA',
      domain: [],
      tooltipObj: {
        visible: false
      },
      firstLoad: true,
      svg: null,
      chart: null
    }
  },
  computed: {
    chartHeight () {
      return (
        this.height - this.padding.top - this.padding.bottom - this.sliderHeight
      )
    },
    chartWidth () {
      return this.width - this.padding.left - this.padding.right
    },
    maximumSliderRange () {
      return Math.round(this.graphData.length / 1.5)
    },
    sliderWidth () {
      return this.width * 0.7 // 70% of whole width;
    },
    sliderMargin () {
      return this.width * 0.15 // 15% each side
    },
    staticDomain () {
      return this.graphData.map(d => d.date)
    },
    xScale () {
      return this.$d3
        .scaleBand()
        .domain(this.domain)
        .range([0, this.chartWidth])
        .paddingInner([0.06])
        .paddingOuter([0.1])
    },
    yScale () {
      const min = this.$d3.min(this.graphData, d =>
        Math.min(d.primary, d.secondary)
      )
      const max = this.$d3.max(this.graphData, d =>
        Math.max(d.primary, d.secondary)
      )

      return this.$d3
        .scaleLinear()
        .domain([Math.min(0, min), max])
        .range([this.chartHeight, 0])
    }
  },
  methods: {
    sliderLabelFormat (date) {
      var dateObj = new Date(date)
      var formatString = this.graphOptions.sliderLabelFormat || '%b %Y'
      return this.$d3.timeFormat(formatString)(dateObj)
    },
    xLabelFormat (date) {
      var dateObj = new Date(date)
      var formatString = this.graphOptions.xLabelFormat || '%Y-%m'
      return this.$d3.timeFormat(formatString)(dateObj)
    },
    xLabelTopFormat (date) {
      var dateObj = new Date(date)
      var formatString = this.graphOptions.xLabelTopFormat || '%b'
      return this.$d3.timeFormat(formatString)(dateObj)
    },
    xLabelBottomFormat (date) {
      var dateObj = new Date(date)
      var formatString = this.graphOptions.xLabelBottomFormat || '%Y'
      return this.$d3.timeFormat(formatString)(dateObj)
    },
    thousandsFormat (value) {
      const locale = this.$d3.formatLocale({
        decimal: ',',
        thousands: ' ',
        grouping: [3]
      })
      return locale.format(',')(value)
    },
    buildTooltipHtml (d, i) {
      var diff = d.primary - d.secondary
      var diffLabel = diff >= 0 ? this.graphOptions.LabelPositive : this.graphOptions.LabelNegative
      var diffColor = diff >= 0 ? this.graphOptions.DifferencePositiveColor : this.graphOptions.DifferenceNegativeColor

      return `<div class="toolTip">
                    <div class="year">
                      <strong><span>${this.sliderLabelFormat(d.date)}</span></strong>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <div class="mr-3">
                        <span class="tooltip-amount-span" style="background-color: ${this.graphOptions.primaryColor}"></span>
                        <span class="ml-2 label-span">${this.graphOptions.primaryLabel}:</span>
                      </div>
                      <div class="ml-4">
                        <span class="amount-span">${this.thousandsFormat(d.primary)}${this.graphOptions.amountSuffix || ''}</span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <div class="mr-3">
                        <span class="tooltip-amount-span" style="background-color: ${this.graphOptions.secondaryColor}"></span>
                        <span class="ml-2 label-span">${this.graphOptions.secondaryLabel}:</span>
                      </div>
                      <div class="ml-4">
                        <span class="amount-span">${this.thousandsFormat(d.secondary)}${this.graphOptions.amountSuffix || ''}</span>
                      </div>
                    </div>
                    <hr class="tooltip-hr">
                    <div class="d-flex justify-content-between mt-2">
                      <div class="mr-3">
                        <span class="tooltip-amount-span" style="background-color: ${diffColor}"></span>
                        <span class="ml-2 label-span">${diffLabel}:</span>
                      </div>
                      <div class="ml-4">
                        <span class="amount-span" style="color: ${diffColor}">${this.thousandsFormat(diff)}${this.graphOptions.amountSuffix || ''}</span>
                      </div>
                    </div>
                  </div>`
    },
    rectmouseover (d) {
      const html = this.buildTooltipHtml(d)

      const x = this.xScale(d.date) + this.xScale.bandwidth() / 2 + this.padding.left
      const y = this.padding.top * 1.5

      this.tooltipObj = {
        x: x,
        y: y,
        html: html,
        visible: true
      }

      // current tick selection
      const xTick = this.chart.select(`#xTick-${d.date}`)

      // make circle bigger and filled white
      xTick
        .select('circle')
        .attr('fill', '#fff')
        .attr('stroke', '#1971ff')
        .attr('r', this.axisCircleSize + 2)

      // display hover rect
      this.chart
        .select('#hoverrect-' + d.date)
        .style('display', 'block')

      // make texts a little bit bigger
      xTick
        .selectAll('text')
        .attr('fill', '#fff')
        .attr('font-weight', 600)
    },
    rectmouseout (d) {
      // current tick selection
      const xTick = this.chart.select(`#xTick-${d.date}`)

      // make circle smaller
      xTick
        .select('circle')
        .attr('fill', '#1971ff')
        .attr('stroke', '#fff')
        .attr('r', this.axisCircleSize)

      // hide hover rect
      this.chart.select('#hoverrect-' + d.date).style('display', 'none')

      // make texts smaller
      xTick
        .selectAll('text')
        .attr('fill', this.darkColor)
        .attr('font-weight', 400)

      // hide tooltip
      this.tooltipObj.visible = false
    },
    calcOpacity (d, i) {
      const length = this.domain.length
      const l = Math.round(length * this.capacityPercentage)
      const h = length - l

      const sc = this.$d3.scaleLinear().clamp(true)

      if (i <= l) {
        sc.domain([0, l]).range([0.25, 1])
        return sc(i)
      }

      if (i >= h) {
        sc.domain([h, this.domain.length - 1]).range([1, 0.25])
        return sc(i)
      }

      return 1
    },
    drawAxis () {
      const tickSize = this.chartHeight
      const xAxis = this.$d3.axisBottom(this.xScale).tickSize(-tickSize)
      const yAxis = this.$d3
        .axisLeft(this.yScale)
        .ticks(5)
        .tickFormat(
          d => this.thousandsFormat(d) + (this.graphOptions.amountSuffix || '')
        )

      // append xAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'xAxis'
      })
        .attr('class', 'xAxis axis')
        .attr('transform', `translate(${0}, ${this.chartHeight})`)
        .call(xAxis)

      // append yAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'yAxis'
      })
        .attr('class', 'yAxis axis')
        .call(yAxis)
    },
    adjustAxis () {
      // let self = this
      const axisType = this.graphOptions.xAxisType
      const ticks = this.chart.select('g.xAxis')
        .selectAll('.tick')
        .attr('opacity', (d, i) => {
          return this.calcOpacity(d, i)
        })
        .attr('id', d => {
          return `xTick-${d}`
        })

      ticks
        .select('line')
        .attr('stroke', this.darkColor)
        .attr('stroke-width', 0.5)
        .attr('stroke-dasharray', '3 1.5')

      // reposition texts within the axis
      ticks
        .select('text')
        .attr('class', 'tick-year')
        .attr('dy', axisType === 'double_row' ? '4em' : '2.5em')
        .attr('fill', this.darkColor)
        .attr('font-family', 'Roboto')
        .text(d =>
          axisType === 'double_row'
            ? this.xLabelBottomFormat(d)
            : this.xLabelFormat(d)
        )

      // append circles
      patternify({
        container: ticks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('fill', this.circleColor)
        .attr('stroke', '#fff')
        .attr('stroke-width', 1)

      if (axisType === 'double_row') {
        // append ages
        patternify({
          container: ticks,
          tag: 'text',
          selector: 'secondaryAxisText',
          data: d => [d]
        })
          .attr('dy', '2.5em')
          .attr('font-family', 'Roboto')
          .text(d => this.xLabelTopFormat(d))
      }

      // adjust xAxis
      const yTicks = this.chart.select('g.yAxis').selectAll('.tick')

      // reposition lines
      yTicks
        .select('line')
        .attr('x1', (d, i) => {
          if (i) {
            return -7
          }
          return 0
        })
        .attr('x2', (d, i) => {
          if (i) {
            if (d === 0) {
              return this.chartWidth
            }
            return 4
          }
          return 0
        })
        .attr('stroke', d => {
          if (d === 0) {
            return '#ffffff'
          }
          return '#A5ADBA'
        })
        .attr('stroke-width', 1.5)

      yTicks
        .select('text')
        .attr('x', -24)
        .attr('font-family', 'Nunito')

      // append circles
      patternify({
        container: yTicks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('cx', -10)
        .attr('fill', '#6D7290')
        .attr('stroke', '#ffffff')
        .attr('stroke-width', 1)
    },
    drawChart (animate) {
      var options = this.graphOptions
      var bandwidth = this.xScale.bandwidth()

      // bars
      const bars = patternify({
        tag: 'g',
        selector: 'bar-group',
        container: this.chart,
        data: this.graphData.filter(d => this.domain.indexOf(d.date) > -1)
      })
        .attr('transform', d => `translate(${this.xScale(d.date)})`)
        .lower()
        .attr('opacity', (d, i) => this.calcOpacity(d, i))

      patternify({
        tag: 'rect',
        selector: 'bar',
        container: bars,
        data: d => {
          var diff = d.primary - d.secondary
          var diffColor =
            diff > 0
              ? options.DifferencePositiveColor
              : options.DifferenceNegativeColor

          var arr = [
            {
              color: options.primaryColor,
              x: 0,
              y: this.yScale(d.primary),
              width: bandwidth / 2,
              height: this.chartHeight - this.yScale(d.primary),
              stroke: this.barStroke
            },
            {
              color: options.secondaryColor,
              x: bandwidth / 2,
              y: this.yScale(d.secondary),
              width: bandwidth / 2,
              height: this.chartHeight - this.yScale(d.secondary),
              stroke: this.barStroke
            }
          ]

          if (diff !== 0) {
            arr.unshift({
              stroke: null,
              color: diffColor,
              x:
                (diff < 0)
                  ? bandwidth / 4
                  : bandwidth / 2,
              y: this.yScale(Math.max(d.primary, d.secondary)),
              width: bandwidth / 4,
              height: this.chartHeight - this.yScale(Math.abs(diff))
            })
          }

          return arr
        }
      })
        .attr('y', this.chartHeight)
        .attr('height', 0)
        .attr('width', d => d.width)
        .attr('x', d => d.x)
        .attr('fill', d => d.color)
        .transition()
        .duration(animate ? this.transitionTime : 0)
        .attr('height', d => d.height)
        .attr('y', d => d.y)

      // hover rectangle
      patternify({
        tag: 'rect',
        selector: 'hoverrect',
        container: bars,
        data: d => [d]
      })
        .attr('id', d => 'hoverrect-' + d.date)
        .attr('opacity', 0.35)
        .attr('fill', 'black')
        .attr('x', 0)
        .style('display', 'none')
        .attr('y', -this.padding.top)
        .attr('width', d => this.xScale.bandwidth())
        .attr('height', d => this.chartHeight + this.padding.top + this.hoverrectMehrYBottom)

      // hidden rectangles
      patternify({
        tag: 'rect',
        selector: 'hiddenrect',
        container: bars,
        data: d => [d]
      })
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', d => this.xScale.bandwidth())
        .attr('height', d => this.chartHeight + this.hoverrectMehrYBottom)
        .on('mouseover', d => this.rectmouseover(d))
        .on('mouseout', d => this.rectmouseout(d))
    },
    setWidth () {
      this.width = this.$el.offsetWidth
    },
    onResize () {
      this.setWidth()
      this.setDomain()
      this.draw(this.firstLoad)
    },
    setDomain () {
      if (!this.domain.length) {
        this.domain = this.staticDomain.slice(0, this.maximumSliderRange + 1)
      }
    },
    draw (animate) {
      this.drawChart(animate)
      this.drawAxis()
      this.adjustAxis()
    },
    onSliderDrag (domain) {
      this.domain = domain
      this.draw(false)
    }
  },
  mounted () {
    this.svg = this.$d3.select(this.$el).select('svg')
    this.chart = this.svg.select('g.chart')
    this.$d3.select(window).on('resize', this.onResize)
    this.onResize()
    this.firstLoad = false
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
