<template>
  <div class="overview">
    <b-container class="main-gradient light" fluid>
      <b-row>
        <b-col no-gutters>
          <b-row>
            <b-col class="chart-container">
              <d3-spirecta-difference-chart
                :graphData="graphData"
                :graphOptions="graphOptions"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import D3SpirectaDifferenceChart from '@/components/d3charts/d3-spirecta-difference-chart/index'

export default {
  components: {
    D3SpirectaDifferenceChart
  },
  data () {
    return {
      graphData: [
        { date: '2019-01', primary: 53941, secondary: 75558 },
        { date: '2019-02', primary: 58362, secondary: 113928 },
        { date: '2019-03', primary: 49067, secondary: 84319 },
        { date: '2019-04', primary: 251390, secondary: 80354 },
        { date: '2019-05', primary: 70009, secondary: 69993 },
        { date: '2019-06', primary: 68207, secondary: 70915 },
        { date: '2019-07', primary: 68887, secondary: 63376 },
        { date: '2019-08', primary: 64835, secondary: 123134 },
        { date: '2019-09', primary: 618937, secondary: 78247 },
        { date: '2019-10', primary: 70387, secondary: 91646 },
        { date: '2019-11', primary: 69851, secondary: 99024 },
        { date: '2019-12', primary: 38486, secondary: 79669 },
        { date: '2020-01', primary: 60000, secondary: 0 },
        { date: '2020-02', primary: 100000, secondary: 100000 },
        { date: '2020-03', primary: 0, secondary: 6512 }
      ],
      graphOptions: {
        primaryColor: '#FEC600',
        primaryLabel: 'Income',
        secondaryColor: '#a6adba',
        secondaryLabel: 'Expenses',
        DifferenceNegativeColor: '#e84752',
        DifferencePositiveColor: '#48b580',
        LabelPositive: 'Surplus',
        LabelNegative: 'Loss',
        amountSuffix: ' €', // or %
        xAxisType: 'double_row', // or double_row,
        xLabelFormat: '%Y-%m',
        xLabelTopFormat: '%b',
        xLabelBottomFormat: '%Y',
        sliderLabelFormat: '%b %Y'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-container {
    padding-top: 50px;
  }
</style>
